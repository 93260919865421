// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  signup: '/signup',
  forgotPassowrd: '/forgot-password',
  resetPassword: '/reset-password'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  account: path(ROOTS_DASHBOARD, '/account'),
  dashboardMemorial: path(ROOTS_DASHBOARD, '/memorial/:url'),
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    users: path(ROOTS_DASHBOARD, '/admin/users'),
    affiliates: path(ROOTS_DASHBOARD, '/admin/affiliates'),
    memorials: path(ROOTS_DASHBOARD, '/admin/memorials'),
    plans: path(ROOTS_DASHBOARD, '/admin/plans'),
    addPlan: path(ROOTS_DASHBOARD, '/admin/add-plan'),
    therapists: path(ROOTS_DASHBOARD, '/admin/therapists'),
    addTherapist: path(ROOTS_DASHBOARD, '/admin/add-therapist'),
    blogs: path(ROOTS_DASHBOARD, '/admin/blogs'),
    addBlog: path(ROOTS_DASHBOARD, '/admin/add-blog'),
    producst: path(ROOTS_DASHBOARD, '/admin/products'),
    addProduct: path(ROOTS_DASHBOARD, '/admin/add-product'),
    pendingRequests: path(ROOTS_DASHBOARD, '/admin/pending-requests'),
  },
};
