import { useNavigate } from "react-router";
import PropTypes from 'prop-types';

import { useAuthContext } from "../auth/useAuthContext";

const { createContext, useContext, useState, useEffect, useMemo } = require("react");

const AppContext = createContext({
    setRedirect: (link) => {}
});

export const useAppContext = () => {
    const context = useContext(AppContext);
    return context
}

const AppContextProvider = ({ children }) => {

    const { isAuthenticated } = useAuthContext();
    const navigate = useNavigate();

    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        if (isAuthenticated && redirect) {
            navigate(redirect);
            setRedirect(null);
        }
        //  eslint-disable-next-line
    }, [isAuthenticated])
    
    const values = useMemo(() => ({
        setRedirect,
    }), [setRedirect]);

    return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export default AppContextProvider;

AppContextProvider.propTypes = {
    children: PropTypes.node
}